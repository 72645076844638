<template>
  <div
    v-if="vars.isVisibleBool"
    :style="`background-color: var(--c-${vars.backgroundColor});
    color: ${vars.textColor ? vars.textColor : 'var(--c-text-secondary)'}`"
    class="tw-mb-10 tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 tw-p-3 tw-text-center"
  >
    <h6>{{ vars.descriptionText }}</h6>
    <nuxt-link :class="`n-${vars.buttonTypeText} n-small`" :href="vars.buttonLinkUrl" :external="true">
      {{ vars.buttonLabelText }}
    </nuxt-link>
  </div>
</template>
<script lang="ts">
export default defineNuxtComponent({
  props: {
    vars: {
      required: true,
    },
  },
});
</script>
