<template>
  <div
    style="z-index: 999 !important"
    :class="{
      'position-unset tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-flex tw-w-full tw-flex-col': variants.HEADER_2 === variant,
    }"
  >
    <nav
      :class="[
        'tw-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-px-6 tw-text-center tw-duration-300',
        'tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-w-full tw-py-4',
        { 'tw-relative !tw-py-2': variants.HEADER_2 === variant },
      ]"
      :style="`z-index: 999!important;
    ${isScrolled || vars.fixedColorBool ? `background-color: var(--c-${vars.backgroundColor});` : 'background-color: transparent;'}
    color: var(--c-${vars.textColor});
`"
    >
      <div class="tw-z-40 tw-mr-auto tw-flex tw-justify-start tw-pt-2 xl:tw-hidden">
        <button id="menu-button" class="focus:outline-none tw-flex" @click="toggleMenu">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 13H0V11H32V13ZM32 20.984H10V19H32V20.984Z" fill="white" />
          </svg>
        </button>
      </div>
      <div class="tw-mr-auto tw-max-w-56 tw-justify-center">
        <nuxt-link
          style="padding: 0 !important; border: none !important; border-radius: 0 !important"
          to="/"
          :external="true"
        >
          <NuxtImg :src="company.logo" alt="logo" />
        </nuxt-link>
      </div>
      <div>
        <div class="tw-ml-4 tw-hidden xl:tw-block">
          <ul class="tw-flex tw-gap-8 tw-align-middle lg:tw-shrink">
            <li
              v-for="(menuGroup, groupIndex) in allMenu[0]"
              :key="`menuGroupIndex-${groupIndex}`"
              class="tw-group tw-relative tw-py-8"
            >
              <div class="tw-flex tw-items-center tw-justify-center">
                <nuxt-link
                  :external="true"
                  :to="menuGroup.url"
                  :target="menuGroup.is_open_new_tab ? '_blank' : ''"
                  class="n-link"
                >
                  <template v-if="variants.HEADER_1 === variant">{{ menuGroup.name }}</template>
                  <h6 v-if="variants.HEADER_2 === variant">
                    {{ menuGroup.name }}
                  </h6>
                </nuxt-link>
                <span
                  v-if="menuGroup.has_sub_menu"
                  class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link tw-ml-2"
                ></span>
              </div>
              <ul
                v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
                class="sub_menu tw-absolute tw-mt-7 tw-hidden tw-rounded-sm tw-bg-white tw-px-6 tw-py-4 tw-text-left tw-text-black tw-shadow-md group-hover:!tw-block"
                style="border-radius: var(--rounded-sm)"
              >
                <li
                  v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
                  :key="`${subMenuIndex}-subMenu-${groupIndex}`"
                  class="tw-w-[220px] tw-py-4"
                  style="border-color: var(--c-border)"
                  :class="{
                    'tw-border-b': subMenuIndex !== menuGroup.sub_menus.length - 1 && variants.HEADER_2 != variant,
                  }"
                >
                  <nuxt-link
                    class="n-link tw-block tw-px-4 tw-py-2 hover:tw-ml-4 hover:tw-text-[var(--c-secondary)]"
                    :external="true"
                    :to="subMenu.url"
                    :target="subMenu.is_open_new_tab ? '_blank' : ''"
                  >
                    <template v-if="variants.HEADER_1 === variant">{{ subMenu.name }}</template>
                    <h6 v-if="variants.HEADER_2 === variant">
                      {{ subMenu.name }}
                    </h6>
                  </nuxt-link>
                </li>
              </ul>
            </li>

            <li class="tw-py-8">
              <nuxt-link :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText}`" :external="true">{{
                vars.buttonLabelText
              }}</nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <NavigationBanner v-if="variants.HEADER_2 === variant" :vars="navigationBanner" />
  </div>

  <div
    v-if="variants.HEADER_1 === variant"
    class="tw-fixed tw-left-0 tw-z-30 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-overflow-y-auto tw-duration-300"
    :class="menuVisible ? 'tw-top-0' : '-tw-top-full'"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: var(--c-${vars.textColor});`"
  >
    <ul class="tw-mt-20 tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 tw-px-12">
      <li v-for="(menuGroup, groupIndex) in allMenu[0]" :key="`menuGroupIndex-${groupIndex}`" class="tw-w-full">
        <div class="tw-flex tw-w-full tw-items-center tw-gap-2 tw-py-3" @click="toggleSubMenu(groupIndex)">
          <nuxt-link
            :external="true"
            :to="menuGroup.has_sub_menu ? '' : menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            class="n-link tw-text-lg tw-font-medium"
          >
            {{ menuGroup.name }}
          </nuxt-link>
          <span
            v-if="menuGroup.has_sub_menu"
            class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link"
            :class="{ 'tw-rotate-180': openSubMenu === groupIndex }"
          ></span>
        </div>
        <ul
          v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
          class="tw-ml-4 tw-space-y-2 tw-overflow-hidden tw-transition-all tw-duration-300"
          :class="openSubMenu === groupIndex ? 'tw-max-h-96' : 'tw-max-h-0'"
        >
          <li
            v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
            :key="`${subMenuIndex}-subMenu-${groupIndex}`"
            class="tw-py-2"
          >
            <nuxt-link
              class="n-link tw-block tw-px-2 tw-py-1 hover:tw-text-[var(--c-secondary)]"
              :external="true"
              :to="subMenu.url"
              :target="subMenu.is_open_new_tab ? '_blank' : ''"
            >
              {{ subMenu.name }}
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="tw-w-full tw-py-3">
        <nuxt-link
          :to="vars.buttonLinkUrl"
          class="tw-block tw-text-center"
          :class="`n-${vars.buttonTypeText}`"
          :external="true"
          >{{ vars.buttonLabelText }}</nuxt-link
        >
      </li>
    </ul>
  </div>

  <div
    v-if="variants.HEADER_2 === variant"
    class="tw-fixed tw-left-0 tw-top-0 tw-h-full tw-w-[90%] tw-overflow-y-auto tw-bg-white tw-text-black tw-duration-300"
    style="z-index: 999"
    :class="menuVisible ? 'tw-translate-x-0' : '-tw-translate-x-[101%]'"
  >
    <ul class="tw-flex tw-flex-col tw-items-start tw-space-y-4 tw-px-6 tw-pt-12">
      <span
        @click="toggleMenu"
        class="tw-absolute tw-right-10 tw-top-10 tw-cursor-pointer tw-rounded-full tw-border-2 tw-p-4"
        ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2806 14.2193C15.3502 14.289 15.4055 14.3717 15.4432 14.4628C15.4809 14.5538 15.5003 14.6514 15.5003 14.7499C15.5003 14.8485 15.4809 14.9461 15.4432 15.0371C15.4055 15.1281 15.3502 15.2109 15.2806 15.2806C15.2109 15.3502 15.1281 15.4055 15.0371 15.4432C14.9461 15.4809 14.8485 15.5003 14.7499 15.5003C14.6514 15.5003 14.5538 15.4809 14.4628 15.4432C14.3717 15.4055 14.289 15.3502 14.2193 15.2806L7.99993 9.06024L1.78055 15.2806C1.63982 15.4213 1.44895 15.5003 1.24993 15.5003C1.05091 15.5003 0.860034 15.4213 0.719304 15.2806C0.578573 15.1398 0.499512 14.949 0.499512 14.7499C0.499512 14.5509 0.578573 14.36 0.719304 14.2193L6.93962 7.99993L0.719304 1.78055C0.578573 1.63982 0.499512 1.44895 0.499512 1.24993C0.499512 1.05091 0.578573 0.860034 0.719304 0.719304C0.860034 0.578573 1.05091 0.499512 1.24993 0.499512C1.44895 0.499512 1.63982 0.578573 1.78055 0.719304L7.99993 6.93962L14.2193 0.719304C14.36 0.578573 14.5509 0.499512 14.7499 0.499512C14.949 0.499512 15.1398 0.578573 15.2806 0.719304C15.4213 0.860034 15.5003 1.05091 15.5003 1.24993C15.5003 1.44895 15.4213 1.63982 15.2806 1.78055L9.06024 7.99993L15.2806 14.2193Z"
            fill="#222222"
          />
        </svg>
      </span>
      <li v-for="(menuGroup, groupIndex) in allMenu[0]" :key="`menuGroupIndex-${groupIndex}`" class="tw-w-full">
        <div class="tw-flex tw-w-full tw-items-center tw-gap-2 tw-py-3" @click="toggleSubMenu(groupIndex)">
          <nuxt-link
            :external="true"
            :to="menuGroup.has_sub_menu ? '' : menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            class="n-link tw-text-lg tw-font-medium"
          >
            <h6>{{ menuGroup.name }}</h6>
          </nuxt-link>
          <span
            v-if="menuGroup.has_sub_menu"
            class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link"
            :class="{ 'tw-rotate-180': openSubMenu === groupIndex }"
          ></span>
        </div>
        <ul
          v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
          class="tw-ml-4 tw-space-y-2 tw-overflow-hidden tw-transition-all tw-duration-300"
          :class="openSubMenu === groupIndex ? 'tw-max-h-96' : 'tw-max-h-0'"
        >
          <li
            v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
            :key="`${subMenuIndex}-subMenu-${groupIndex}`"
            class="tw-py-2"
          >
            <nuxt-link
              class="n-link tw-block tw-px-2 tw-py-1 hover:tw-text-[var(--c-secondary)]"
              :external="true"
              :to="subMenu.url"
              :target="subMenu.is_open_new_tab ? '_blank' : ''"
            >
              <h6>{{ subMenu.name }}</h6>
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="tw-w-full tw-py-3">
        <nuxt-link
          :to="vars.buttonLinkUrl"
          class="tw-block tw-text-center"
          :class="`n-${vars.buttonTypeText}`"
          :external="true"
          >{{ vars.buttonLabelText }}</nuxt-link
        >
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { mapState } from 'pinia';
import { MenuTypes, ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';
import NavigationBanner from '~/components/theme4/components/NavigationBanner';
import Button from '~/components/common/Button.vue';
type Menu = {};

export default defineNuxtComponent({
  name: 'Theme4BaseHeaderOne',
  components: { NcButton, NavigationBanner },

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      menuVisible: false,
      isScrolled: false,
      openSubMenu: null,
      variants: {
        HEADER_1: 'HEADER_1',
        HEADER_2: 'HEADER_2',
      },
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['headerMenuItems']),
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),

    headerMenu() {
      return this.headerMenuItems as Menu[];
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    allMenu() {
      const period = 9;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    columnOneMenu() {
      const content = this.component.variables['column_one_content-text'];
      if (content === MenuTypes.HEADER_MENU) {
        return this.headerMenu;
      }
      return [];
    },

    navigationBanner() {
      return {
        isVisibleBool: this.vars.bannerBool,
        backgroundColor: this.vars.bannerBackgroundColor,
        descriptionText: this.vars.bannerDescriptionText,
        buttonLinkUrl: this.vars.bannerButtonLinkUrl,
        buttonLabelText: this.vars.bannerButtonLabelText,
        buttonTypeText: this.vars.bannerButtonTypeText,
      };
    },
  },

  methods: {
    toggleSubMenu(index) {
      this.openSubMenu = this.openSubMenu === index ? null : index;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
</script>
